import styled from 'styled-components'
import { Media, typography, spacing } from '@renderbus/common/theme'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1550px;
  margin: auto;
  ${Media.lessThan(Media.small)} {
    padding: 0;
  }
`
export const ParticipationWayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 170px;
`
export const RulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding: 0 170px;
`
export const TitleImg = styled.img`
  padding: 90px 0;
  ${Media.lessThan(Media.small)} {
    width: 235px;
    padding: 30px 0;
  }
`
export const RulesTitleImg = styled(TitleImg)`
  padding-bottom: 25px;
`
export const StepImgWrap = styled.div`
  width: 100%;
  text-align: center;
  img:nth-child(2) {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 20px;
    img:first-child {
      display: none;
    }
    img:nth-child(2) {
      width: 100%;
      display: block;
    }
  }
`
export const PrizeList = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`
export const PrizeItem = styled.div`
  width: 320px;
  height: 280px;
  margin-left: 22px;
  margin-bottom: ${spacing.large};
  text-align: center;
  color: #ffffff;
  p {
    margin-top: ${spacing.base};
  }
  ${Media.lessThan(Media.small)} {
    width: 160px;
    height: 140px;
    margin-left: ${spacing.small};
    font-size: ${typography.textThin};
    p {
      margin-top: ${spacing.small};
    }
  }
`
export const PrizeImgWrap = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 30px;
  background: #222931;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${Media.lessThan(Media.small)} {
    height: 125px;
    img {
      height: 60%;
      max-width: 90%;
    }
  }
`
export const RulesList = styled.div`
  color: #ccc;
  width: 100%;
  padding-left: 50px;
  p {
    height: 30px;
    font-size: ${typography.h4};
    font-weight: 400;
    color: #cccccc;
    margin: ${spacing.base} 0;
    a {
      cursor: pointer;
      color: #ffa454;
    }
  }
  span {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: linear-gradient(30deg, #fdfdc8 0%, #ffa454 0%, rgba(250, 245, 209, 0) 100%);
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
    line-height: 30px;
  }
  ${Media.lessThan(Media.small)} {
    padding-left: 0;
    p {
      height: auto;
      line-height: 1.5;
      font-size: ${typography.textSmall};
      margin: 0;
      button {
        display: block;
        margin: 22px auto 5px;
      }
    }
    span {
      background: none;
      width: auto;
      height: auto;
      margin-right: 0;
      ::after {
        content: '、';
      }
    }
  }
`
export const DescriptionList = styled.div`
  width: 100%;
  padding-left: 50px;
  padding-bottom: ${spacing.base};
  font-size: ${typography.h4};
  font-weight: 400;
  color: #cccccc;
  a {
    color: #ffd69d;
    cursor: pointer;
  }
  p {
    margin: ${spacing.small} 0;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: ${spacing.large};
    font-size: ${typography.textSmall};
    padding-left: 0;
  }
`
export const QrcodeList = styled.div`
  margin-bottom: 100px;
  display: flex;
  align-self: flex-start;
  color: #999;
  font-size: ${typography.textSmall};
  width: 100%;
`
export const QrcodeWrap = styled.span`
  width: 210px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
  }
  span {
    margin-top: 14px;
    text-align: center;
  }
  ${Media.lessThan(Media.small)} {
    width: 32%;
    font-size: ${typography.textThin};
    img {
      width: 80px;
      height: 80px;
    }
  }
`
